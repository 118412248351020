import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/NewLayout"

const BreezePage = () => (
  
<Layout>
  <SEO title="Home" />
  <h1 
    style={{marginTop: "15rem", 
    marginLeft: "5rem", 
    marginBottom: "10rem"
    }}>Welcome to the Breeze Salon page <br/> Under Construction
  </h1>
</Layout>
    

)

export default BreezePage